if ($('#observation_pilot_date_at_day').length >0) {
    $('#observation_pilot_date_at_day').addClass('invisible');
}

if ($('#observation_air_control_accDateAt_day').length >0) {
    $('#observation_air_control_accDateAt_day').addClass('invisible');
}

if ($('#btn-agreement').length >0) {
    $('.contract').scroll(function() {
        let disable = $('#agreement').height()-50 > ($(this).scrollTop() + $(this).height());
        $('#btn-agreement').prop('disabled', disable);
    });
}

if ($('#modalContract').length >0) {
    $('#modalContract').modal('show');
}

if ( $('input[type=file]').length >0 ) {
    $('input[type=file]').on('change', function () {
        let fileName = $(this).val();
        $(this).next('.custom-file-label').html(fileName);
    });
}
