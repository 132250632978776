'use strict';

require('../css/global.scss');

const $ = require('jquery');
window.$ = window.jQuery = $;
window.Popper = require('popper.js');

require('bootstrap/js/dist/dropdown');
require('bootstrap/js/dist/util');
require('bootstrap/js/dist/modal');
require('bootstrap/js/dist/collapse');
require('bootstrap/js/dist/popover');
require('bootstrap/js/dist/tab');
require('bootstrap/js/dist/tooltip');
require('bootstrap/js/dist/alert');

require('../js/theme');
require('../js/custom');
